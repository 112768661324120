import React from "react";
import PropTypes from "prop-types";
import { Spin } from "antd";
import { ErrorMessage } from "../ErrorMessage";
import { LoadingOutlined } from "@ant-design/icons";
import upload from "../../assets/upload.png";
import file from "../../assets/file.png";

export const UploadProfileImage = ({
  className = "form-control",
  type = "text",
  name = "",
  id = "",
  disabled = false,
  value = "",
  label = "",
  changeHandler,
  blurHandler,
  errorMessage = "",
  required = false,
  placeholder,
  accept = "",
  isLoading,
}) => {
  return (
    <div className={`input_${type}`}>
      <label class={`${className}`}>
        <div className="upload-user-image flex justify-center items-center">
          {isLoading ? (
            <Spin
              indicator={
                <LoadingOutlined
                  style={{
                    fontSize: 24,
                  }}
                  spin
                />
              }
            />
          ) : (
            <span className="text-normal text-white">+</span>
          )}
        </div>
        <input
          type={type}
          name={name}
          id={id}
          accept={accept}
          placeholder={placeholder}
          className={className}
          onChange={changeHandler}
          onBlur={blurHandler}
          disabled={isLoading ? true : disabled}
          required={required}
          autoComplete={type === "password" ? "new-password" : "off"}
        />
      </label>
      <div className="text-yellow-500 text-sm">
        {!isLoading && value && className !== "custom-csv-upload" ? (
          <a href={value} target="_blank" download className="flex">
            <img src={file} alt="download Attachment" className="h-5 mr-2" />
            Attachment
          </a>
        ) : (
          ""
        )}
      </div>
      {errorMessage ? (
        <span>
          <ErrorMessage message={errorMessage} />
        </span>
      ) : (
        ""
      )}
    </div>
  );
};
UploadProfileImage.propTypes = {
  className: PropTypes.string,
  errorMessage: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  value: PropTypes.any,
  type: PropTypes.string.isRequired,
  changeHandler: PropTypes.func,
  blurHandler: PropTypes.func,
  required: PropTypes.bool,
  placeholder: PropTypes.string,
};
