export const TypeText = ({ item, index, handleChange, changeData }) => {
  return (
    <div className="flex mt-2">
      <div className="flex flex-col">
        <label className="mb-1 mt-2 paragraphs_font text-xs">
          Field Label<span className="text-red-500"> *</span>
        </label>
        <input
          type="text"
          value={item?.label}
          name="label"
          className="tabs-input-border px-2 py-1 rounded paragraphs_font text-sm"
          onChange={(e) => {
            handleChange(index, e);
          }}
          onBlur={() => {
            changeData();
          }}
        />
      </div>
      {item.fieldType !== "date" && item.fieldType !== "rating" && (
        <div className="flex flex-col ml-3">
          <label className="mb-1 mt-2 paragraphs_font text-xs">
            Field PlaceHolder <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            value={item?.placeHolder}
            name="placeHolder"
            className="tabs-input-border px-2 py-1 rounded paragraphs_font text-sm"
            onChange={(e) => {
              handleChange(index, e);
            }}
            onBlur={() => {
              changeData();
            }}
          />
        </div>
      )}
    </div>
  );
};

export const TypeTextValidations = ({
  item,
  index,
  handleChange,
  changeData,
}) => {
  return (
    <div className=" pt-4">
      <div className="flex">
        <div class="wrapper w-11">
          <div class="switch_box box_1 ">
            <input
              type="checkbox"
              class="switch_11"
              name="isRequired"
              checked={item?.isRequired}
              onChange={(e) => {
                handleChange(index, e);
              }}
            />
          </div>
        </div>
        <label className="margin-label paragraphs_font text-sm">
          {item?.isRequired ? "Required" : "Not Required"}
        </label>
      </div>
      <div className="flex flex-col pt-4 w-2/5">
        <label className="mb-1 mt-2 paragraphs_font">Character Limit</label>
        <input
          type="number"
          value={
            item.fieldType === "date" ||
            item.fieldType === "select" ||
            item.fieldType === "rating" ||
            item.fieldType === "barCode" ||
            item.fieldType === "checkBox" ||
            item.fieldType === "radio"
              ? "0"
              : item?.minLength
          }
          disabled={
            item.fieldType === "date" ||
            item.fieldType === "select" ||
            item.fieldType === "rating" ||
            item.fieldType === "barCode" ||
            item.fieldType === "checkBox" ||
            item.fieldType === "radio"
              ? true
              : false
          }
          name="minLength"
          minlength="4"
          className="tabs-input-border px-2 py-1 rounded paragraphs_font text-sm"
          onChange={(e) => {
            if (e.target.value.length <= 3 && e.target.value <= 150) {
              handleChange(index, e);
            } else {
              e.target.value = "";
            }
          }}
          onBlur={() => {
            changeData();
          }}
        />
      </div>
    </div>
  );
};
