import React from "react";
import PropTypes from "prop-types";

export const TextArea = ({
  className = "form-control",
  type = "text",
  name = "",
  id = "",
  disabled = false,
  value = "",
  label = "",
  changeHandler,
  blurHandler,
  errorMessage = "",
  required = false,
  placeholder,
  rows,
  cols,
  activity,
  labelClass,
  ThemeColor
}) => {
  return (
    <>
      <div className="mb-1">
        <label
          className={`${
            activity
              ? "text-black text-xs paragraphs_font"
              : `text-xs paragraphs_font ${
                  labelClass
                    ? !ThemeColor
                      ? "text-white"
                      : "text-black"
                    : "text-white"
                }`
          }`}
          htmlFor={name}
        >
          {label} {required ? <span className="text-red-500 ml-1">*</span> : ""}
        </label>
      </div>

      <textarea
        type={type}
        name={name}
        id={id}
        placeholder={placeholder}
        className={className}
        onChange={changeHandler}
        onBlur={blurHandler}
        value={value}
        disabled={disabled}
        required={required}
        cols={cols}
        rows={rows}
      />
    </>
  );
};
TextArea.propTypes = {
  className: PropTypes.string,
  errorMessage: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  value: PropTypes.any,
  type: PropTypes.string.isRequired,
  changeHandler: PropTypes.func,
  blurHandler: PropTypes.func,
  required: PropTypes.bool,
  placeholder: PropTypes.string,
};
