import React, { useEffect, useState } from "react";
import logout from "assets/logout.png";
import intelytics from "assets/intelytics.png";
import search_icon from "assets/search_icon.png";
import logout_black from "assets/logout_black.png";
import notify_filled from "assets/notify_filled.png";
import notifications_icon from "assets/notifications_icon.png";
import { Select } from "../../Select";
import { logOut } from "utils/helpers/common";
import { getCookie } from "services/session/cookies";
import { logoutAPI } from "services/api/auth";
import { InputField } from "components";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ChangeLanguage } from "services/api/common";
import { BreadcrumbComponent } from "components/BreadCrumbs";
import { useSelector, useDispatch } from "react-redux";
import { REQUEST_TYPES, ENDPOINTS } from "services/middleware/url";

const Header = ({ changeThemeColor, ThemeColor, pathname }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [user, setUser] = useState();
  const [selected, setSelected] = useState();
  const [t, i18n] = useTranslation("global");
  const slectedLanguage = useSelector((state) => state?.common?.Language);
  const navItems = getCookie("navItems" ? "navItems" : []) ?? [];
  const onchangeLanguageHandler = (value) => {
    i18n.changeLanguage(value);
    dispatch(ChangeLanguage(value));
  };
console.log(navItems);
  const onchangeHandler = (value) => {
    navigate(value);
    const nav = JSON.parse(navItems);
    const selectedItem = nav.find((item) => {
      if (item.value === value) {
        return item.label;
      }
    });
    setSelected(selectedItem.value);
    localStorage.setItem("pathname", selectedItem.value);
  };

  useEffect(() => {
    try {
      const user = JSON.parse(getCookie("user" ? "user" : {}));
      setUser(user);
    } catch (err) {
      console.log("error", err);
    }
  }, []);

  const logoutHandler = () => {
    dispatch(logoutAPI(REQUEST_TYPES.POST, ENDPOINTS.LOGOUT, "logout"));
    logOut();
    navigate("/login");
  };

  useEffect(() => {
    if (pathname !== "/speech-checking") {
      const nav = JSON.parse(navItems);
      const selectedItem = nav.find((item) => {
        if (item.value === pathname) {
          return item;
        } else {
          return;
        }
      });
      if (selectedItem?.value) {
        setSelected(selectedItem.value);
      } else {
        const selectedPathname = localStorage.getItem("pathname");
        setSelected(selectedPathname);
      }
    } else {
      console.log("path");
    }
  }, [pathname]);

  return (
    <>
      <div className={`flex fixed top-0 w-full z-20 flex-col pl-11`}>
        <div
          className={`w-full flex justify-between pt-2 pb-2 ${
            ThemeColor ? "bg-black drop-shadow" : "bg-[#F6F8F9] drop-shadow"
          }`}
        >
          <div className="w-2/5  flex items-center">
            <img src={intelytics} alt="logo" className="pl-14" />
          </div>
          <div className="w-6/12 flex justify-end pr-10 items-center">
            {pathname === "/main-dashboard" ? (
              <div
                className={`flex items-center custom_search h-8 px-3 w-6/12 rounded-sm ${
                  ThemeColor ? "bg-white " : "bg-black"
                }`}
              >
                <img src={search_icon} alt="search" className="h-3 pr-2" />
                <InputField
                  id="50"
                  type="text"
                  name="Search or type a command"
                  label=""
                  className={`text-white outline-none border-l-2 border-blue-500 pl-1 ${
                    ThemeColor ? "bg-white " : "bg-black"
                  }`}
                  placeholder="Password"
                  disabled={false}
                />
              </div>
            ) : (
              ""
            )}
            <div
              className={`w-3/5 flex justify-end pl-5 items-center ${
                ThemeColor ? "text-white " : "text-black"
              }`}
            >
              <div
                className={`pl-2 flex ${
                  pathname === "/user-management-dashboard" ||
                  pathname === "/pop-management"
                    ? " pr-2 "
                    : ""
                }`}
              >
                {/* <Select
                  id="20"
                  label=""
                  className={`w-full h-8 rounded outline-none paragraphs_font  text-sm  pl-1 pr-5 ${
                    ThemeColor
                      ? "text-white field_bg-blue border font-thin language-select"
                      : "text-black bg-white card-board font-normal language-select-black-icon"
                  }`}
                  changeHandler={(event) =>
                    onchangeLanguageHandler(event.target.value)
                  }
                  value={slectedLanguage}
                  name="language"
                  options={[
                    {
                      label: "English (US)",
                      value: "en",
                    },
                    {
                      label: "Arabic",
                      value: "ar",
                    },
                  ]}
                  required={false}
                /> */}
                <div className="ml-2 mr-2">
                  {pathname !== "/speech-checking" ? (
                    <Select
                      id="20"
                      label=""
                      className={`w-full h-8 rounded  outline-none paragraphs_font  text-sm  pl-1 pr-5 ${
                        ThemeColor
                          ? "text-white field_bg-blue border font-thin language-select"
                          : "text-black bg-white card-board font-normal language-select-black-icon"
                      }`}
                      changeHandler={(event) =>
                        onchangeHandler(event.target.value)
                      }
                      value={selected}
                      name="language"
                      options={JSON.parse(navItems)?.map(item => {
                        return {...item, 'label': t(`navigation.${item.label}`) }
                    })}
                      required={false}
                    />
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="flex items-center">
                {/* {pathname !== "/user-management-dashboard" &&
              pathname !== "/pop-management" && (
                <div
                  className="text-white py-2 px-3"
                  onClick={() => {
                    changeThemeColor();
                  }}
                >
                  {ThemeColor ? (
                    <img src={sun} alt="sun_icon" className="cursor-pointer" />
                  ) : (
                    <img
                      src={moon}
                      alt="mood_icon"
                      className="cursor-pointer"
                    />
                  )}
                </div>
              )} */}
                <div>
                  {ThemeColor ? (
                    <img
                      src={notifications_icon}
                      alt="noitfy"
                      className="cursor-pointer"
                    />
                  ) : (
                    <img
                      src={notify_filled}
                      alt="notify_filled"
                      className="cursor-pointer"
                    />
                  )}
                </div>
              </div>
              <div className="pl-2 pr-2">
                <div className="capitalize text-sm">
                  {user?.userName ? user?.userName : "User Name"}
                </div>
              </div>
              <div>
                {ThemeColor ? (
                  <img
                    src={logout}
                    alt="logout"
                    className="cursor-pointer"
                    onClick={() => {
                      logoutHandler();
                    }}
                  />
                ) : (
                  <img
                    src={logout_black}
                    alt="logout_black"
                    className="cursor-pointer"
                    onClick={() => {
                      logoutHandler();
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <div>
          <BreadcrumbComponent />
        </div>
      </div>
    </>
  );
};

export default Header;
