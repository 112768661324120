import React, { useEffect, useState } from "react";
import { Pagination, Table } from "antd";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

export const DataTable = ({
  Columns,
  data,
  permissionSpecific = false,
  setSelectedRowKeys,
  selectedRowKeys = [],
  withOutCheckBox = false,
  handleSelectIds = () => { },
}) => {
  const ThemeColor = useSelector((state) => state?.common?.Theme);
  const [selectedRows, setSelectedRows] = useState([]);
  const [t, i18n] = useTranslation('global');
  const dataWithKeys = data.map((item, index) => ({
    ...item,
    key: index.toString(),
  }));

  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10;

  const onPageChange = (page) => {
    setCurrentPage(page);
  };


  useEffect(() => {
    const tableElement =
      document.getElementsByClassName("ant-table-content")[0];
    const tableHeader = document.getElementsByClassName("ant-table-tbody")[0];
    const rows = document.getElementsByClassName("ant-table-cell");
    const icon = document.getElementsByClassName("anticon-filter");

    if (ThemeColor) {
      tableElement.classList.add(
        "table_bg_color_white_th",
        "table_bg_color_white_td"
      );
      tableElement.classList.remove(
        "table_bg_color_black_th",
        "table_bg_color_black_td"
      );
      tableHeader.classList.remove("table_rows_black");
      for (let i = 0; i < rows.length; i++) {
        rows[i].classList.remove("border-bottom");
      }
      for (let i = 0; i < icon.length; i++) {
        icon[i].classList.remove("icon-white");
      }
    } else {
      tableElement.classList.remove(
        "table_bg_color_white_th",
        "table_bg_color_white_td"
      );
      tableElement.classList.add(
        "table_bg_color_black_th",
        "table_bg_color_black_td"
      );
      tableHeader.classList.add("table_rows_black");
      for (let i = 0; i < rows.length; i++) {
        rows[i].classList.add("border-bottom");
      }
      for (let i = 0; i < icon.length; i++) {
        icon[i].classList.add("icon-white");
      }
    }
  }, [ThemeColor]);

  const onSelectChange = (newSelectedRowKeys) => {

    if (permissionSpecific) {
      // get the keys of pages
      let currentPageIds = new Set(paginatedData.map(item => item.key));
      setSelectedRowKeys(prev => {
        // Create a new Set to automatically handle uniqueness
        const updatedSelection = new Set([
          ...prev.filter(item => !currentPageIds.has(item)), // Remove old page's selections
          ...newSelectedRowKeys, // Add new page's selections
        ]);
        // Convert the Set back to an array for the selectedRowKeys
        return [...updatedSelection];
      });
    } else {
      setSelectedRowKeys(newSelectedRowKeys);
    }
  };

  useEffect(() => {
    handleSelectIds(getRowIds(selectedRowKeys));
  }, [selectedRowKeys])

  const getRowIds = (newSelectedRowKeys) => {
    return dataWithKeys
      .filter((item) => newSelectedRowKeys.includes(item.key))
      .map((item) => item.id);
  };

  const toggleSelectAll = () => {
    if (selectedRowKeys.length === data.length) {
      setSelectedRowKeys([]);
    } else {
      setSelectedRowKeys(dataWithKeys.map((r) => r.key));
    }
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    onSelectAll: toggleSelectAll,
  };

  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const paginatedData = dataWithKeys.slice(startIndex, endIndex);

  const itemRender = (_, type, originalElement) => {
    const itemStyle = {
      color: ThemeColor ? "#1D242E" : "#fff",
      fontFamily: "'Sora', sans-serif",
      paddingBottom: "11px",
    };
    if (type === "prev") {
      return (
        <a
          style={{
            color: ThemeColor ? "#0073EE" : "#0073EE",
            fontFamily: "'Sora', sans-serif",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "10px",
          }}
        >
          <svg
            width="17"
            height="8"
            viewBox="0 0 17 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M16 4.5C16.2761 4.5 16.5 4.27614 16.5 4C16.5 3.72386 16.2761 3.5 16 3.5V4.5ZM0.646447 3.64645C0.451184 3.84171 0.451184 4.15829 0.646447 4.35355L3.82843 7.53553C4.02369 7.7308 4.34027 7.7308 4.53553 7.53553C4.7308 7.34027 4.7308 7.02369 4.53553 6.82843L1.70711 4L4.53553 1.17157C4.7308 0.976311 4.7308 0.659728 4.53553 0.464466C4.34027 0.269204 4.02369 0.269204 3.82843 0.464466L0.646447 3.64645ZM16 3.5L1 3.5L1 4.5L16 4.5V3.5Z"
              fill="#CBCBCB"
            />
          </svg>
          {`${t('action.prev')} ${currentPage === 1 ? "" : currentPage - 1}`}
        </a>
      );
    }
    if (type === "next") {
      return (
        <a
          style={{
            color: ThemeColor ? "#0073EE" : "#0073EE",
            fontFamily: "'Sora', sans-serif",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "10px",
          }}
        >
          {`${t('action.next')} ${currentPage === Math.ceil(data.length / 10) ? "" : currentPage + 1
            }`}
          <svg
            width="21"
            height="8"
            viewBox="0 0 21 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.999958 3.5C0.723816 3.5 0.499958 3.72386 0.499958 4C0.499958 4.27614 0.723816 4.5 0.999958 4.5V3.5ZM20.6103 4.35355C20.8055 4.15829 20.8055 3.84171 20.6103 3.64645L17.4283 0.464466C17.233 0.269204 16.9164 0.269204 16.7212 0.464466C16.5259 0.659728 16.5259 0.976311 16.7212 1.17157L19.5496 4L16.7212 6.82843C16.5259 7.02369 16.5259 7.34027 16.7212 7.53553C16.9164 7.7308 17.233 7.7308 17.4283 7.53553L20.6103 4.35355ZM0.999958 4.5L20.2567 4.5V3.5L0.999958 3.5V4.5Z"
              fill="#CBCBCB"
            />
          </svg>
        </a>
      );
    }
    if (type === "page") {
      return React.cloneElement(originalElement, {
        style: itemStyle,
      });
    }
    return originalElement;
  };

  const paginationStyle = {
    textAlign: "center",
    background: ThemeColor ? "#fff" : "#000B15",
    justifyContent: "center",
  };

  return (
    <div
      className={`ant-table-wrapper ${ThemeColor ? "light-theme" : "dark-theme"
        }`}
    >
      <Table
        rowSelection={withOutCheckBox ? null : rowSelection}
        columns={Columns}
        dataSource={paginatedData}
        pagination={false}
        scroll={{ x: true }}
      />
      <Pagination
        total={data.length}
        current={currentPage}
        pageSize={pageSize}
        showSizeChanger={false}
        onChange={onPageChange}
        itemRender={itemRender}
        style={paginationStyle}
      />
    </div>
  );
};
