import React, { useState } from "react";
import { ColorPicker } from "antd";

export const PickColor = ({
  id,
  name,
  value,
  changeHandler,
  label,
  hidden,
}) => {
  return (
    <>
      {!hidden && (
        <>
          <div>
            <p className="text-xs paragraphs_font text-white mb-1">
              {label}
              <span className="text-red-500 ml-1">*</span>
            </p>
          </div>
          <div className="w-full text-white outline-none inputfield pt-2.5 flex justify-between pr-2 pl-2">
            <p className="text-white">{value ? value : "Choose Color"}</p>
            <input
              type="color"
              name={name}
              id={id}
              value={value}
              hidden={hidden}
              className="bg-dark-theme outline-none field w-6"
              onChange={changeHandler}
            />
          </div>
        </>
      )}
    </>
  );
};
