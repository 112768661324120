import { toast } from "react-toastify";
import { apiCaller } from "../../middleware/api-caller";
import { getCookie } from "services/session/cookies";
import { setCookie } from "services/session/cookies";
import { REACT_APP_API_BASE_URL } from "../../../utils/constants/constatns";
import { ENDPOINTS, REQUEST_TYPES } from "../../../services/middleware/url";
import {
  GetPermission,
  LoginAction,
  UserTypesAction,
} from "../../../store/actions/Auth";
import { errorMessages, successMessage } from "utils/helpers";
import {
  ApiRequestedAction,
  ApiFulfilledAction,
  ApiRejectedAction,
} from "../../../store/actions/ApiCallStatus";

export const loginAPI = (
  method,
  url,
  data,
  apiCallFor,
  navigate,
  redirectLink,
  setIsLoading
) => {
  return async (dispatch) => {
    dispatch(ApiRequestedAction({ apiCallFor }));
    setIsLoading(true)
    const myJson = await apiCaller({ method, url: url, data });
    setIsLoading(false)
    const user = JSON.stringify(myJson?.data?.data);
    if (
      (myJson && myJson?.data?.statusCode === 200) ||
      myJson?.data?.statusCode === 201
    ) {
      dispatch(ApiFulfilledAction({ apiCallFor: apiCallFor, message: "" }));
      dispatch(LoginAction(myJson?.data?.data));
      setCookie("accessToken", myJson.headers.authorization);
      setCookie("user", user);
      toast.success("Login successfully.");
      return navigate(redirectLink);
    } else {
      const message =
        (myJson?.data?.message || myJson?.response?.data?.message) ??
        "Something went wrong";
      const statusCode =
        (myJson?.response?.data?.message || myJson?.data?.message) ?? 500;
      toast.error(errorMessages(message));
      dispatch(
        ApiRejectedAction({
          statusCode,
          apiCallFor: apiCallFor,
          message,
        })
      );
      if (message === "Account not verified") {
        return navigate("/verify-account");
      }
    }
  };
};

export const RegisterAPI = (method, url, data, apiCallFor, navigate) => {
  return async (dispatch) => {
    dispatch(ApiRequestedAction({ apiCallFor }));
    const myJson = await apiCaller({ method, url: url, data });
    if (
      myJson &&
      (myJson?.data?.statusCode === 200) | (myJson?.data?.statusCode === 201)
    ) {
      dispatch(ApiFulfilledAction({ apiCallFor: apiCallFor, message: "" }));
      toast.success(myJson?.data.message || "Register successfully.");
      return myJson?.data;
    } else {
      const message = myJson?.response?.data?.message ?? "Something went wrong";
      const statusCode = myJson?.response?.data?.message ?? 500;
      toast.error(
        errorMessages(Array.isArray(message) ? message.join(", ") : message)
      );
      dispatch(
        ApiRejectedAction({
          statusCode,
          apiCallFor: apiCallFor,
          message,
        })
      );
    }
  };
};

export const UpdateItem = (method, url, data, id, apiCallFor, navigate) => {
  return async (dispatch) => {
    dispatch(ApiRequestedAction({ apiCallFor }));
    const myJson = await apiCaller({ method, url: `${url}/${id}`, data });
    if (
      myJson &&
      (myJson?.data?.statusCode === 200) | (myJson?.data?.statusCode === 201)
    ) {
      dispatch(ApiFulfilledAction({ apiCallFor: apiCallFor, message: "" }));
      toast.success("Update successfully.");
      return myJson?.data;
    } else {
      const message = myJson?.data?.message ?? "Something went wrong";
      const statusCode = myJson?.data?.message ?? 500;
      toast.error(errorMessages(message));
      dispatch(
        ApiRejectedAction({
          statusCode,
          apiCallFor: apiCallFor,
          message,
        })
      );
    }
  };
};

export const RegisterFieldStaff = (method, url, data, apiCallFor, navigate) => {
  return async (dispatch) => {
    dispatch(ApiRequestedAction({ apiCallFor }));
    const myJson = await apiCaller({ method, url: url, data });
    if (
      myJson &&
      (myJson?.data?.statusCode === 200) | (myJson?.data?.statusCode === 201)
    ) {
      dispatch(ApiFulfilledAction({ apiCallFor: apiCallFor, message: "" }));
      toast.success("Register successfully.");
    } else {
      const message = myJson?.data?.message ?? "Something went wrong";
      const statusCode = myJson?.data?.statusCode;
      toast.error(errorMessages(message));
      dispatch(
        ApiRejectedAction({
          statusCode,
          apiCallFor: apiCallFor,
          message,
        })
      );
    }
  };
};

export const CreateUser = async (method, url, data, apiCallFor) => {
  const myJson = await apiCaller({ method, url: url, data });
  if (
    myJson &&
    (myJson?.data?.statusCode === 200) | (myJson?.data?.statusCode === 201)
  ) {
    const { data } = myJson;
    if (data?.data) {
      toast.success("Information stored successfully.");
      return data?.data;
    }
  } else {
    const message =
      (myJson?.response?.data?.message || myJson?.response?.data?.message) ??
      "Something went wrong";
    toast.error(errorMessages(message));
  }
};

export const deleteItem = async (method, url, data, apiCallFor) => {
  const myJson = await apiCaller({ method, url: `${url}/${data}` });
  if (
    myJson &&
    (myJson?.data?.statusCode === 200) | (myJson?.data?.statusCode === 201)
  ) {
    const { data } = myJson;
    if (data?.data) {
      toast.success("Information Delete successfully.");
      return myJson?.data;
    }
  } else {
    const message =
      (myJson?.response?.data?.message || myJson?.response?.data?.message) ??
      "Something went wrong";
    toast.error(errorMessages(message));
  }
};

export const verifyAccount = async (
  method,
  url,
  data,
  apiCallFor,
  navigate,
  redirectLink
) => {
  const myJson = await apiCaller({ method, url: `${url}${data}` });
  try {
    if (
      myJson &&
      (myJson?.data?.statusCode === 200) | (myJson?.data?.statusCode === 201)
    ) {
      const { data } = myJson;
      const message = myJson?.data?.message ?? "Account verified Successfully";
      toast.success(message);
      navigate(redirectLink);
      return;
    } else if (myJson?.data?.statusCode === 400) {
      // Handle token expiration
      toast.error("Verification link expired");
    } else {
      const message = myJson?.data?.message ?? "Something went wrong";
      toast.error(errorMessages(message));
    }
  } catch (error) {
    const errorMessage =
      error.response?.data?.message;
    toast.error(errorMessage);
  }
};

export const RgenerateToken = async (method, url, data, apiCallFor) => {
  const myJson = await apiCaller({ method, url: `${url}${data}` });
  if (
    (myJson && myJson?.data?.statusCode === 200) ||
    myJson?.data?.statusCode === 201
  ) {
    const { data } = myJson;
    if (data?.statusCode === 200) {
      toast.success(
        data.message ||
          "New token has been generated. Please Check the email for verification"
      );
      return data?.data;
    }
  } else {
    const message =
      (myJson?.response?.data?.message || myJson?.response?.data?.message) ??
      "Something went wrong";
    toast.error(errorMessages(message));
  }
};

export const UplaodingFile = async (method, url, data, apiCallFor) => {
  const myJson = await apiCaller({ method, url: url, data });
  if (
    myJson &&
    (myJson?.data?.statusCode === 200) | (myJson?.data?.statusCode === 201)
  ) {
    const { data } = myJson;
    const message = myJson?.data?.message;
    if (myJson?.data && apiCallFor === "uploadCSV") {
      toast.success(message);
      return myJson?.data;
    } else {
      toast.success(message);
      return data?.data;
    }
  } else {
    const message =
      (myJson?.data?.message || myJson?.data?.message) ??
      "Something went wrong";
    toast.error(errorMessages(message));
  }
};

export const logoutAPI = (method, url, apiCallFor) => {
  return async (dispatch) => {
    dispatch(ApiRequestedAction({ apiCallFor }));
    const myJson = await apiCaller({ method, url: url });
    if (
      myJson &&
      (myJson?.data?.statusCode === 200) | (myJson?.data?.statusCode === 201)
    ) {
      dispatch(ApiFulfilledAction({ apiCallFor: apiCallFor, message: "" }));
    } else {
      const message = myJson?.data?.message;
      const statusCode = myJson?.data?.statusCode;
      dispatch(
        ApiRejectedAction({
          statusCode,
          apiCallFor: apiCallFor,
          message,
        })
      );
    }
  };
};

export const getAllUserTypes = (method, url, apiCallFor) => {
  return async (dispatch) => {
    dispatch(ApiRequestedAction({ apiCallFor }));
    const myJson = await apiCaller({ method, url });
    if (
      myJson &&
      (myJson?.data?.statusCode === 200) | (myJson?.data?.statusCode === 201)
    ) {
      const { data } = myJson?.data;
      dispatch(UserTypesAction(data));
      dispatch(ApiFulfilledAction({ apiCallFor: apiCallFor, message: "" }));
    } else {
      const message =
        (myJson?.data?.message || myJson?.response?.data?.message) ??
        "Something went wrong";
      const statusCode =
        (myJson?.response?.data?.message || myJson?.data?.message) ?? 500;
      toast.error(errorMessages(message));
      dispatch(
        ApiRejectedAction({ statusCode, apiCallFor: apiCallFor, message })
      );
    }
  };
};

export const CheckSpeechAPI = (method, url, data, apiCallFor, navigate) => {
  return async (dispatch) => {
    dispatch(ApiRequestedAction({ apiCallFor }));
    const myJson = await apiCaller({ method, url: url, data });
    if (
      myJson &&
      (myJson?.data?.statusCode === 200) | (myJson?.data?.statusCode === 201)
    ) {
      dispatch(ApiFulfilledAction({ apiCallFor: apiCallFor, message: "" }));
      toast.success(myJson?.data.message);
      return myJson?.data;
    } else {
      const message = myJson?.response?.data?.message ?? "Something went wrong";
      const statusCode = myJson?.response?.data?.message ?? 500;
      toast.error(
        errorMessages(Array.isArray(message) ? message.join(", ") : message)
      );
      dispatch(
        ApiRejectedAction({
          statusCode,
          apiCallFor: apiCallFor,
          message,
        })
      );
    }
  };
};

export const ChangeStatus = async (method, url, data, apiCallFor) => {
  const myJson = await apiCaller({ method, url: url, data });
  if (
    myJson &&
    (myJson?.data?.statusCode === 200) | (myJson?.data?.statusCode === 201)
  ) {
    const { data } = myJson;
    if (data?.data) {
      toast.success(data.message);
      return myJson?.data;
    }
  } else {
    const message =
      (myJson?.response?.data?.message || myJson?.response?.data?.message) ??
      "Something went wrong";
    toast.error(errorMessages(message));
  }
};
